import { faStar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import "swiper/css"
import "swiper/css/effect-fade"
import { SwiperSlide } from "swiper/react"
import SwiperSlider from "../../SwiperSlider"

export default () => {
  const {
    allReactPpcReviewsJson: { ReviewRecords },
  } = useStaticQuery(graphql`
    query {
      allReactPpcReviewsJson {
        ReviewRecords: nodes {
          author
          designation
          organization
          description
          authorImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          authorAlt
        }
      }
    }
  `)

  return (
    <SwiperSlider autoplay>
      <ul className="flex flex-col lg:max-w-[calc(25%-1rem)] md:max-w-[calc(50%-1rem)] w-full pl-[35px]">
        {ReviewRecords.map(
          (
            {
              author,
              designation,
              organization,
              description,
              authorImage,
              authorAlt,
            },
            idx
          ) => {
            authorImage = getImage(authorImage)
            return (
              <li key={`${author}-${idx}`} className="py-[0.625rem] relative">
                <SwiperSlide key={`${idx}-${author}`}>
                  <div className="pr-[1.125rem] pl-[68px] py-[1.125rem] flex items-start justify-start flex-wrap rounded-[0.625rem] border-solid border-2  border-[#161616] bg-gradient-to-t from-[#000] to-[#161616] ml-[38px]">
                    <div className="space-x-5">
                      <p className="xl:text-[0.938rem] text-sm leading-5 pb-5  xl:leading-[1.7rem] text-white font-gorditaregular">
                        &ldquo;{description} &rdquo;
                      </p>
                      {/* <img src={Quote} alt="" width="32px" height="10px" /> */}
                    </div>
                    <div className="flex items-center auther w-[70%]">
                      <GatsbyImage
                        image={authorImage}
                        alt={authorAlt}
                        className="w-[5rem] h-[5rem] rounded-[10px] absolute left-0 top-[50%] translate-y-[-50%] "
                      />
                      <div className="flex flex-wrap">
                        <h3 className="font-blancotrialbold text-white text-[15px] leading-[1.125rem] mr-[6px]">
                          {author}
                        </h3>
                        <p className="text-grey text-sm leading-[0.875rem] ">
                          {designation},
                          <span className="text-center transition-all">
                            &nbsp;{organization}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-end w-[30%]">
                      <div className="flex text-primary relative text-sm">
                        <FontAwesomeIcon
                          icon={faStar}
                          className="w-[0.75rem] mr-[0.188rem]"
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          className="w-[0.75rem] mr-[0.188rem]"
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          className="w-[0.75rem] mr-[0.188rem]"
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          className="w-[0.75rem] mr-[0.188rem]"
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          className="w-[0.75rem]"
                        />
                      </div>
                      <h3 className="text-white text-xs font-gorditabold ml-2">
                        5.0
                      </h3>
                    </div>
                  </div>
                </SwiperSlide>
              </li>
            )
          }
        )}
      </ul>
    </SwiperSlider>
  )
}
