import React from "react"
import BestPrice from "../../../assets/img/best-price.inline.svg"
import Time from "../../../assets/img/time.inline.svg"

const Pricing = ({ hourlyPrice, monthlyPrice, setShowModal }) => {
  return (
    <div
      id="pricing"
      className="bg-[url('../assets/img/testimonial.webp')] bg-no-repeat bg-auto bg-[top_7rem_center] w-full"
    >
      <h2 className="md:text-[2rem] text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] pb-1 px-4 font-blancotrialbold text-center md:max-w-[36.125rem] mx-auto">
        Assemble your react team starting at an affordable price
        <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
      </h2>
      <ul className="flex flex-wrap gap-x-10 justify-center md:pt-24 pt-6 px-[0.938rem] lg:px-12 items-baseline">
        <li
          className="md:max-w-[28.563rem] w-full mt-8 md:mt-0 rounded-[0.625rem] p-10 flex flex-col items-center justify-center shadow-[rgba(0,16,147,0.02)]"
          style={{ backgroundColor: "rgba(255,255,255,0.65)" }}
        >
          <div className="justify-center text-center">
            <Time className="max-w-[3rem]  mx-auto" />
            <h3 className="text-black md:text-2xl text-xl pb-3 pt-5 font-blancotrialbold">
              Hire on an hourly basis
              <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
            </h3>

            <h1 className="text-black md:text-4xl text-sm font-gorditabold pb-8 pt-2">
              {hourlyPrice}/Hour
            </h1>
            <p className="text-grey md:font-gorditamedium font-gorditaregular md:text-base text-[0.938rem] leading-7 text-left">
              — Pay only for hours worked
            </p>
            <p className="text-grey md:font-gorditamedium font-gorditaregular md:text-base text-[0.938rem] leading-7 text-left">
              — Ideal if looking for time and material model
            </p>
            <div className="w-full md:w-auto mt-10">
              <button
                type="button"
                onClick={() => setShowModal(true)}
                className="inline-white text-white bg-primary text-base rounded-3xl px-[4rem] py-[0.7rem] font-gorditamedium hover:bg-orange-500 hover:text-white transition-all duration-500"
              >
                Get Started
              </button>
            </div>
          </div>
        </li>
        <li
          className="md:max-w-[28.563rem] w-full mt-8 md:mt-0 rounded-[0.625rem] p-10 flex flex-col items-center justify-center shadow-[rgba(0,16,147,0.02)] relative"
          style={{ backgroundColor: "rgba(255,255,255,0.65)" }}
        >
          <span className="save-tag font-gorditabold bg-gradient-to-r from-[#CE75BF] to-[rgba(145,178,239)] inline-block absolute top-[0.625rem] right-[-0.313rem] text-base py-[0.313rem] pr-[25px] text-center w-[7.063rem] text-white before:rounded-tl-[4px] before:rounded-bl-[4px] before:w-[2.5rem] before:h-[100%] before:bg-[#CE75BF] before:absolute before:top-0 before:left-[-1.875rem] before:skew-x-[-25deg] before:z-[-1] z-[1] after:w-0 after:h-0 after:absolute after:border-t-[0.438rem] after:border-r-[7px] after:border-r-transparent after:border-solid after:border-t-black after:right-0 after:bottom-[-0.438rem]">
            Save ⁓12%{" "}
          </span>
          <div className="justify-center text-center">
            <BestPrice className="max-w-[3rem]  mx-auto" />
            <h2 className="text-black md:text-2xl text-xl pb-3 pt-5 font-blancotrialbold">
              Hire at fixed monthly rate
              <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
            </h2>
            <h1 className="text-black md:text-4xl text-sm font-gorditabold pb-8 pt-2">
              {monthlyPrice}/Month
            </h1>
            <p className="text-grey md:font-gorditamedium font-gorditaregular md:text-base text-[0.938rem] leading-7 text-left">
              — 160 man-hours per month dedicated
            </p>
            <p className="text-grey md:font-gorditamedium font-gorditaregular md:text-base text-[0.938rem] leading-7 text-left">
              — Ideal if you need dedicated developers
            </p>
            <div className="w-full md:w-auto mt-10">
              <button
                type="button"
                onClick={() => setShowModal(true)}
                className="inline-white text-white bg-primary text-base rounded-3xl px-[4rem] py-[0.7rem] font-gorditamedium hover:bg-orange-500 hover:text-white transition-all duration-500 "
              >
                Get Started
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default Pricing
