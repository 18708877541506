import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import PortfolioImagesListing from "../PortfolioImagesListing"

export default () => {
  const {
    allReactDevPpcPortfolioJson: { nodes: Portfolio },
  } = useStaticQuery(graphql`
    query {
      allReactDevPpcPortfolioJson(limit: 10) {
        nodes {
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 2110)
            }
          }
          imageAlt
        }
      }
    }
  `)

  return (
    <section className="lg:pb-14 md:pb-10 pb-8">
      <div className="container">
        <div className="text-center md:w-10/12 w-full mx-auto mb-7">
          <h3
            id="portfolio"
            className=" max-w-full mx-auto md:text-[2rem] text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] font-blancotrialbold"
          >
            Projects powered by our development team
            <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
          </h3>
        </div>
        <PortfolioImagesListing Portfolio={Portfolio} />
      </div>
    </section>
  )
}
