import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export default ({ setShowModal }) => (
  <section className="result-sec pt-[1.875rem] md:pt-[0] md:pb-[5.625rem] pb-10">
    <div className="container">
      <div className="flex flex-wrap justify-between align-middle">
        <div className="my-3 lg:max-w-[22.5rem] w-full">
          <h2 className="md:text-3xl text-[1.625rem] pb-1 font-blancotrialbold md:leading-[3rem] leading-[2.4rem]">
            How we select the top 3% of the talent
            <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
          </h2>
          <p className="text-base pb-7 font-gorditamedium leading-[1.7rem] text-grey">
            At Techuz, we pride ourselves on selecting only the top 3% of the
            talent for our clients' projects. With a rigorous four-step process,
            we ensure that we're identifying the best candidates with the skills
            and expertise needed to deliver exceptional results.
          </p>
        </div>
        <div className="w-full lg:max-w-[57%] md:p-10 p-5  bg-[url('../assets/img/Oval.webp')] bg-no-repeat bg-cover bg-top rounded-2xl">
          <div className="lg:p-8 p-5 bg-white-200 md:mb-10 mb-5 rounded-lg">
            <StaticImage
              src="../../../assets/img/vote.png"
              alt="vote"
              className="max-w-[3rem]"
              placeholder="blurred"
            />
            <h3 className="text-black md:text-2xl text-xl font-gorditamedium pb-3 pt-5">
              01. Screening candidates
            </h3>

            <h4 className="text-black md:text-lg text-sm font-gorditamedium pb-3 pt-5 italic">
              20% of the candidates pass
            </h4>
            <p className="text-grey md:font-gorditamedium font-gorditaregular md:text-base text-[0.938rem] leading-7">
              We screen candidates based on their technical capabilities,
              experience, and past projects. We look for developers with a
              proven track record of success, as well as those who have
              experience in a range of tools and technologies.
            </p>
          </div>
          <div className="lg:p-8 p-5 bg-white-200 md:mb-10 mb-5 rounded-lg">
            <StaticImage
              src="../../../assets/img/review.png"
              alt="review"
              className="max-w-[3rem]"
              placeholder="blurred"
            />
            <h3 className="text-black md:text-2xl text-xl font-gorditamedium pb-3 pt-5">
              02. In-depth review & test
            </h3>

            <h4 className="text-black md:text-lg text-sm font-gorditamedium pb-3 pt-5 italic">
              7% of the candidates pass
            </h4>
            <p className="text-grey md:font-gorditamedium font-gorditaregular md:text-base text-[0.938rem] leading-7">
              Next, we conduct an in-depth skill review, communication and
              behavioral test to assess the candidate's communication skills,
              attitude, and ability to work effectively in a team environment.
            </p>
          </div>
          <div className="lg:p-8 p-5 bg-white-200 md:mb-10 mb-5 rounded-lg">
            <StaticImage
              src="../../../assets/img/task-list.png"
              alt="task"
              className="max-w-[3rem]"
              placeholder="blurred"
            />
            <h3 className="text-black md:text-2xl text-xl font-gorditamedium pb-3 pt-5">
              03. Live task
            </h3>

            <h4 className="text-black md:text-lg text-sm font-gorditamedium pb-3 pt-5 italic">
              3.3% of the candidates pass
            </h4>
            <p className="text-grey md:font-gorditamedium font-gorditaregular md:text-base text-[0.938rem] leading-7">
              Then we assign them test projects to evaluate their real-world
              skills, competence, and professionalism. This allows us to assess
              their ability to work under pressure, meet tight deadlines, and
              deliver high-quality work that meets our clients' needs.
            </p>
          </div>
          <div className="lg:p-8 p-5 bg-white-200 md:mb-10 mb-5 rounded-lg">
            <StaticImage
              src="../../../assets/img/reuse.png"
              alt="reuse"
              className="max-w-[3rem]"
              placeholder="blurred"
            />
            <h3 className="text-black md:text-2xl text-xl font-gorditamedium pb-3 pt-5">
              04. Consistent work
            </h3>

            <h4 className="text-black md:text-lg text-sm font-gorditamedium pb-3 pt-5 italic">
              3% of the candidates pass
            </h4>
            <p className="text-grey md:font-gorditamedium font-gorditaregular md:text-base text-[0.938rem] leading-7">
              Finally, with the selected candidates we ensure they provide
              consistent exemplary work of delivering outstanding results time
              and time again.
            </p>
          </div>
        </div>
      </div>
    </div>
    <section className="bg-black py-[1.875rem] lg:py-16 mt-[1.875rem] md:mt-[5.625rem]">
      <div className="container">
        <div className="flex flex-wrap md:flex-nowrap md:justify-between justify-center items-center lg:px-20 px-0 text-center md:text-left">
          <div className="mb-5 md:mb-0 w-full md:w-auto">
            <h3 className="text-white text-[1.875rem] md:text-[2rem] font-blancotrialmedium leading-[2.875rem] md:leading-[3.25rem] mb-0 md:max-w-[33rem]">
              Ready to accelerate your development with the top 3% of React
              engineers?
            </h3>
          </div>
          <div className="w-full md:w-auto">
            <button
              type="button"
              onClick={() => setShowModal(true)}
              className="inline-white text-white bg-primary rounded-[1.875rem] px-[2rem] py-4 font-gorditamedium hover:bg-orange-500 hover:text-white transition-all duration-500 md:w-[15.625rem] lg:w-[22.5rem] text-xl"
            >
              Hire top engineers
            </button>
          </div>
        </div>
      </div>
    </section>
  </section>
)
