import React from "react"
import { faStar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CluchWhite from "../../../assets/img/clutchWhite.webp"

import UpworkLogo from "../../../assets/img/upwork1.png"
const Reveiws = () => {
  return (
    <ul className="flex justify-between flex-wrap">
      <li className="flex flex-wrap items-center sm:my-0">
        <img src={CluchWhite} alt="Clutch" className="w-[3.938rem]" />
        <div className="flex text-primary relative md:text-base text-sm ml-[0.625rem] mr-[0.375rem]">
          <FontAwesomeIcon icon={faStar} width="12px" height="12px" />
          <FontAwesomeIcon icon={faStar} width="12px" height="12px" />
          <FontAwesomeIcon icon={faStar} width="12px" height="12px" />
          <FontAwesomeIcon icon={faStar} width="12px" height="12px" />
          <FontAwesomeIcon icon={faStar} width="12px" height="12px" />
        </div>
        <p className=" text-white text-xs leading-[1.5rem] font-gorditabold">
          32 reviews
        </p>
      </li>
      <li className="flex flex-wrap items-center mt-3 md:mt-1">
        <img src={UpworkLogo} alt="upwork" className="w-[4.375rem]" />
        <p className="text-white text-xs leading-[1.5rem] font-gorditabold ml-[10px]">
          1,30,000+ hours delivered
        </p>
      </li>
    </ul>
  )
}

export default Reveiws
