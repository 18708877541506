import React from "react"
import StarRating from "../../../assets/img/star-rating.inline.svg"
import CheckMark from "../../../assets/img/check-mark.inline.svg"
const PerfomantDevs = ({ setShowModal }) => {
  return (
    <div>
      <div className="container">
        <div className="flex flex-wrap justify-between">
          <div className="w-full lg:max-w-[41%]">
            <h2 className="md:text-[2.25rem] text-[1.625rem] pb-5 md:pb-[3.438rem] md:leading-[3rem] leading-[2.375rem] font-blancotrialbold ">
              4 reasons CTOs hire engineers from Techuz
              <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
            </h2>
            <div className="w-full p-6 bg-[url('../assets/img/Oval.webp')] bg-no-repeat bg-cover bg-top rounded-2xl">
              <div className="flex items-end">
                <StarRating />
              </div>
              <div className="title-sec max-w-[18.75rem] md:mb-0 md:mt-7 mb-14 w-full relative before:content[''] before:absolute before:bg-[url('../assets/img/blue-quote.svg')] md:before:w-[5.375rem] before:w-5 md:before:h-[4.625rem] before:h-9 before:left-0 before:bg-cover">
                <p className="text-1xl text-black pb-1 md:leading-[2rem] leading-[2.5rem] z-10 relative">
                  The engineers from Techuz can quickly digest information,
                  allowing them to produce excellent code that meet highly
                  technical requirements. Other highlights include phenomenal
                  communication and project management. Their ability to adapt
                  is impressive.
                </p>
              </div>
              <div>
                <h3 className="pb-1 text-xl font-blancotrialbold">Görkem</h3>
                <p className="text-sm text-grey">
                  Co-Founder at
                  <a
                    href="https://count.ly/"
                    target="_blank"
                    className="transition-all text-primary hover:underline"
                  >
                    &nbsp; Count.ly
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="w-full lg:max-w-[51.5%] pt-0 mt-[1.875rem] md:mt-0">
            <div className="pb-10">
              <CheckMark className="max-w-[2.375rem]" />
              <h3 className="text-black md:text-2xl text-xl font-gorditamedium pb-3 pt-5">
                Our engineers have extensive experience
              </h3>
              <p className="text-grey font-gorditamedium text-base leading-7">
                90% of our React developers are senior and mind-senior level
                with extensive experience of working on different projects,
                teams, and tools. You don’t require any hand-holding, rather
                they proactively take care of your development goals, adding
                value to your project from day one.
              </p>
            </div>
            <div className="pb-10">
              <CheckMark className="max-w-[2.375rem]" />
              <h4 className="text-black md:text-2xl text-xl font-gorditamedium pb-3 pt-5">
                Our engineers stay for long
              </h4>
              <p className="text-grey font-gorditamedium text-base leading-7">
                Our commitment to long-term partnerships extends to both our
                clients and team members. With team members who have been with
                us since our inception, we have maintained a healthy employee
                turnover rate of 10 per cent. This provides our clients with the
                confidence to hire developers for extended projects and get
                seamless collaboration and consistent value.
              </p>
            </div>
            <div className="pb-10">
              <CheckMark className="max-w-[2.375rem]" />
              <h4 className="text-black md:text-2xl text-xl font-gorditamedium pb-3 pt-5">
                Our engineers are problem solvers
              </h4>
              <p className="text-grey font-gorditamedium text-base leading-7">
                We deeply believe that the very essence of programming is
                problem-solving and that’s reflected in our developers’ work. We
                take a step further to the technical aspect and consider the
                real-world problems, solutions, and user experience. With a
                problem-solving mindset we take more thoughtful and strategic
                approach to development, resulting in better outcomes for our
                clients.
              </p>
            </div>
            <div>
              <CheckMark className="max-w-[2.375rem]" />
              <h4 className="text-black md:text-2xl text-xl font-gorditamedium pb-3 pt-5">
                We prioritize learning & development
              </h4>
              <p className="text-grey font-gorditamedium text-base leading-7">
                We constantly work to enhance our developers’ expertise so that
                they are capable of tackling even the most complex tasks.
                Additionally, we prioritize the personal growth of our
                developers, ensuring that they have the resources and support
                they need to reach their full potential. This directly
                translates into better outcomes for our clients, who get
                consistently high-quality work and innovative solutions from our
                team.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className="mt-[1.875rem] md:mt-[5.625rem] bg-black py-[1.875rem] lg:py-16">
        <div className="container">
          <div className="flex flex-wrap md:flex-nowrap md:justify-between justify-center items-center lg:px-20 px-0 text-center md:text-left">
            <div className="mb-[1.875rem] md:mb-0 w-full md:w-auto">
              <h3 className="text-white text-[1.875rem] md:text-[2rem] font-blancotrialmedium leading-[2.875rem] md:leading-[3.25rem] mb-0 md:max-w-[32.5rem]">
                Hire React developers trusted by top CTOs
                <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
              </h3>
            </div>
            <div className="w-full md:w-auto">
              <button
                type="button"
                onClick={() => setShowModal(true)}
                className="inline-white text-white bg-primary text-base rounded-3xl px-[2rem] py-[0.7rem] font-gorditamedium hover:bg-orange-500 hover:text-white transition-all duration-500 md:w-[15.625rem] lg:w-[22.5rem]"
              >
                Hire and supercharge your project
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default PerfomantDevs
