import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Technologies = () => {
  return (
    <section className="lg:pt-[5.625rem] pt-14 md:pt-20 lg:pb-[0.625rem] md:pb-10 pb-8">
      <div className="title-sec m-auto w-full max-w-full md:pb-10 pb-8">
        <h2 className="md:text-[2rem] text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] pb-1 font-blancotrialbold text-center md:max-w-[46.875rem] mx-auto">
          Our React engineers are well-versed in the leading tools and
          technologies
          <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
        </h2>
      </div>
      <div className="container">
        <ul className="flex flex-wrap justify-between">
          <li className="flex flex-col  items-start text-center mr-[5.438rem] md:mb-16">
            <h2 className="text-5 md:text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] pb-4 text-center font-gorditamedium">
              App setup
            </h2>
            <ul className="flex">
              <li className="flex flex-col items-center">
                <StaticImage
                  className="md:mb-[1rem] flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/react-sketch.png"
                  alt="React Sketch"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  React App
                </h3>
              </li>
            </ul>
          </li>
          <li className="flex flex-col  items-start text-center mr-[5.438rem] md:mb-16">
            <h2 className="text-5 md:text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] pb-4 text-center font-gorditamedium">
              Routing
            </h2>
            <ul className="flex gap-x-5">
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem] flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/react-router.png"
                  alt="React Router"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  React Router
                </h3>
              </li>
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem]  flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/react-router-text.png"
                  alt="React Router"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  React Router
                </h3>
              </li>
            </ul>
          </li>
          <li className="flex flex-col  items-start text-center mr-[5.438rem] md:mb-16">
            <h2 className="text-5 md:text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] pb-4 text-center font-gorditamedium">
              Framework
            </h2>
            <ul className="flex">
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem] flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/gatsby-logo.png"
                  alt="Gatsby"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  Gatsby
                </h3>
              </li>
            </ul>
          </li>
          <li className="flex flex-col  items-start text-center mr-[5.438rem] md:mb-16">
            <h2 className="text-5 md:text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] pb-4 text-center font-gorditamedium">
              Testing
            </h2>
            <ul className="flex gap-x-3">
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem] flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/enzyme.png"
                  alt="Enzyme"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  Enzyme
                </h3>
              </li>
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem] flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/react-testing.png"
                  alt="React Testing"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  React Testing Library
                </h3>
              </li>
            </ul>
          </li>
          <li className="flex flex-col  items-start text-center mr-[5.438rem] md:mb-16">
            <h2 className="text-5 md:text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] pb-4 text-center font-gorditamedium">
              State Management
            </h2>
            <ul className="flex gap-x-3">
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem] flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/redux.png"
                  alt="Redux"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  Redux
                </h3>
              </li>
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem] flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/redux.png"
                  alt="Redux Thunk"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  Redux Thunk
                </h3>
              </li>
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem]  flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/redux-saga.png"
                  alt="Redux Saga"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  Redux Saga
                </h3>
              </li>
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem]  flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/mobx.png"
                  alt="Mobx"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  MobX
                </h3>
              </li>
            </ul>
          </li>
          <li className="flex flex-col items-start text-center mr-[5.438rem] md:mb-16">
            <h2 className="text-5 md:text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] pb-4 text-center font-gorditamedium">
              Misc
            </h2>
            <ul className="flex gap-x-3 flex-wrap">
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem]  flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/format.png"
                  alt="Format JS"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  Format js
                </h3>
              </li>
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem] flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/preact.png"
                  alt="Preact"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  Preact
                </h3>
              </li>
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem] flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/storybook.png"
                  alt="StoryBook"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  Storybook
                </h3>
              </li>
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem] flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/styled-components.png"
                  alt="Styled Components"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  Styled Components
                </h3>
              </li>
            </ul>
          </li>
          <li className="flex flex-col items-start text-center mr-[5.438rem] md:mb-16">
            <h2 className="text-5 md:text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] pb-4 text-center font-gorditamedium">
              UI Components
            </h2>
            <ul className="grid grid-cols-2 md:grid-cols-4 gap-x-3 ">
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem]  flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/materialUi.png"
                  alt="Material UI"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  Material UI
                </h3>
              </li>
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem] flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/react-bootstrap.png"
                  alt="React Bootstrap"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  React Bootstrap
                </h3>
              </li>
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem]  flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/react-strap.png"
                  alt="React Strap"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  React Strap
                </h3>
              </li>
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem]  flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/prime.png"
                  alt="Prime React"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  Prime React
                </h3>
              </li>
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem]  flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/chakra-ui.png"
                  alt="Chakra UI"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  Chakra UI
                </h3>
              </li>
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem]  flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/ant-design.png"
                  alt="Ant Design"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  Ant Design
                </h3>
              </li>
            </ul>
          </li>
          <li className="flex flex-col items-start text-center mr-[5.438rem] md:mb-16">
            <h2 className="text-5 md:text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] pb-4 text-center font-gorditamedium">
              Forms
            </h2>
            <ul className="flex gap-x-3 flex-wrap">
              <li className="flex flex-col mb-4">
                <StaticImage
                  className="md:mb-[1rem]  flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/react-hook.png"
                  alt="React Hook"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  React Hook Form
                </h3>
              </li>
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem] lg:p-8 flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/final-form.png"
                  alt="Final Form"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7">
                  React Final Form
                </h3>
              </li>
              <li className="flex flex-col  mb-4">
                <StaticImage
                  className="md:mb-[1rem] flex justify-center items-center rounded-[1.25rem] bg-white-700 w-[4.688rem] lg:w-[6.125rem]"
                  src="../../../assets/img/formik.png"
                  alt="Formik"
                  placeholder="blurred"
                />
                <h3 className="text-grey font-gorditamedium text-base leading-7 text-left">
                  Formik
                </h3>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default Technologies
