import React from "react"
import CheckMarkBlue from "../../../assets/img/check-mark-blue.inline.svg"
import { useContextValues } from "../../../context/NavBarContext"
import { Currency } from "../../FormFields/Budget"
import ContactForm from "../ContactForm"
import Reveiws from "./Reviews"
import ReviewsSlider from "./ReviewsSlider"

type Props = {
  currency?: Currency
}

const VettedDevelopers = () => {
  return (
    <>
      <ul className="flex flex-wrap mb-5 md:mb-0">
        <li className="md:font-gorditamedium font-gorditaregular leading-[1.7rem] text-grey text-left w-full md:w-1/2 mb-1 md:mb-5">
          <div className="flex items-center space-x-2">
            <CheckMarkBlue width="18px" height="18px" />
            <span className="flex text-left italic text-sm xl:text-base">
              Only the top 3% of the talent
            </span>
          </div>
        </li>
        <li className="md:font-gorditamedium font-gorditaregular leading-[1.7rem] text-grey text-left w-full md:w-1/2 mb-1 md:mb-5">
          <div className="flex items-center space-x-3">
            <CheckMarkBlue width="18px" height="18px" />
            <span className="flex text-left italic text-sm xl:text-base">
              On board within 24 hours
            </span>
          </div>
        </li>
        <li className="md:font-gorditamedium font-gorditaregular leading-[1.7rem] text-grey text-left w-full md:w-1/2 mb-1">
          <div className="flex items-left space-x-3">
            <CheckMarkBlue width="18px" height="18px" />
            <span className="flex text-center italic text-sm xl:text-base">
              Flexible scale up or down
            </span>
          </div>
        </li>
        <li className="md:font-gorditamedium font-gorditaregular leading-[1.7rem] text-grey text-left w-full md:w-1/2">
          <div className="flex items-left space-x-3">
            <CheckMarkBlue width="18px" height="18px" />
            <span className="flex text-center italic text-sm xl:text-base">
              {" "}
              Simple contracts
            </span>
          </div>
        </li>
      </ul>
    </>
  )
}
const PgHdr = ({ currency = "USD" }: Props) => {
  const { isMobile } = useContextValues()
  return (
    <div id="contactForm" className="container py-5">
      <div className="flex flex-wrap  justify-between align-middle">
        <div className="xl:max-w-[34.375rem] lg:max-w-[28rem] max-w-full w-full md:mt-[3.125rem]">
          <div className="w-full">
            <h1 className="max-[767px]:text-[1.9rem] max-[767px]:leading-[2.5rem] text-[2.2rem] leading-[2.75rem] pb-[0.625rem] font-blancotrialbold text-white text-left">
              Hire top 3% React <br></br> engineers to supercharge your product
              development
              <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
            </h1>
            <p className="md:text-base text-[1.125rem] pb-10 md:font-gorditamedium leading-[1.7rem] text-grey text-left">
              (Without overwhelming hiring process, interrupting your workflow,
              or paying high salaries.)
            </p>
            <VettedDevelopers />
          </div>
          <div className="max-w-[40rem] pt-2 md:pt-5 max-[1440px]:pt-[1.75rem]">
            <Reveiws />
            {isMobile && (
              <div className="mt-4">
                <ReviewsSlider />
                <ContactForm
                  submitBtnTxt="Get Top 3% React Developers"
                  currency={currency}
                  spacingCss="md:pb-5"
                  submitBtnCss="text-white bg-primary md:text-[1.25rem] text-base text-center rounded-[1.875rem] px-3 py-4 w-full font-gorditamedium hover:bg-orange-500 hover:text-white transition-all"
                  formCss="rounded-xl"
                  NameCss="text-lg placeholder-[#000] w-full text-black max-[1023px]:py-4  max-[1023px]:px-7 max-[1366px]:py-[0.625rem] max-[1366px]:px-7 p-[0.938rem] min-[1399px]:p-[0.875rem] bg-white rounded-md"
                  emailCss="text-lg placeholder-[#000] w-full max-[1023px]:py-4  max-[1023px]:px-7 text-black max-[1366px]:py-[0.625rem] max-[1366px]:px-7 p-[0.938rem] min-[1399px]:p-[0.875rem] bg-white rounded-md"
                  phoneNumberCss="text-lg placeholder-[#000] max-[1023px]:py-4  max-[1023px]:px-7 max-[1023px]:pl-[2.875rem] w-full text-black max-[1366px]:py-[0.625rem] max-[1366px]:px-7 p-[0.938rem] min-[1399px]:pr-[0.875rem] bg-white rounded-md pl-[2.938rem]"
                  budgetCss="bg-[#FFFFFF] text-lg text-[#000] max-[1023px]:py-4  max-[1023px]:px-7 max-[1366px]:py-[0.625rem] max-[1366px]:px-7 p-[0.938rem] min-[1399px]:p-[0.875rem] rounded-md focus:outline-none foucs:border-b-[#C8CBD2]"
                  textAreaCss="placeholder-[#000] text-lg max-[1023px]:py-4  max-[1023px]:px-7 max-[1366px]:py-[0.625rem] max-[1366px]:px-7 p-[0.938rem] min-[1399px]:p-[0.875rem]  bg-white rounded-md border focus:outline-none focus-visible:outline-none"
                />
              </div>
            )}
          </div>
        </div>
        {!isMobile && (
          <div className="xl:w-[calc(100%-34.375rem)] lg:w-[calc(100%-28rem)] w-full lg:mt-0 mt-10 xl:pl-[4.688rem] lg:pl-10 pl-0 relative before:w-[14.25rem] before:h-[14.25rem] before:rounded-full before:bg-[#FFF6EA] before:absolute before:-z-10 xl:before:right-[-5rem] before:right-[-1rem] xl:before:top-[-4.25rem] before:top-[-3.5rem]">
            <ReviewsSlider />
            <ContactForm
              submitBtnTxt="Get Top 3% React Developers"
              currency={currency}
              spacingCss="md:pb-5"
              submitBtnCss="text-white bg-primary min-[1441px]:text-[1.25rem] max-[1440px]:text-[1.15rem] text-base text-center rounded-[1.875rem] px-3 py-4 max-[1440px]:py-[0.75rem] w-full font-gorditamedium hover:bg-orange-500 hover:text-white transition-all"
              formCss="rounded-xl"
              NameCss="text-lg  placeholder-[#000] w-full text-black max-[1366px]:py-[0.625rem] max-[1366px]:px-7 p-[0.938rem] min-[1399px]:p-[0.875rem] bg-white rounded-md max-[1440px]:leading-[1rem] max-[1440px]:text-[0.95rem]"
              emailCss="text-lg placeholder-[#000] w-full text-black max-[1366px]:py-[0.625rem] max-[1366px]:px-7 p-[0.938rem] min-[1399px]:p-[0.875rem] bg-white rounded-md max-[1440px]:leading-[1rem] max-[1440px]:text-[0.95rem]"
              phoneNumberCss="text-lg placeholder-[#000] w-full text-black max-[1366px]:py-[0.625rem] max-[1366px]:px-7 p-[0.938rem] pl-[3.125rem] max-[1366px]:pl-[2.938rem] min-[1399px]:pr-[0.875rem] bg-white rounded-md max-[1440px]:leading-[1rem] max-[1440px]:text-[0.95rem]"
              budgetCss="bg-[#FFFFFF] text-lg text-[#000] max-[1366px]:py-[0.625rem] max-[1366px]:px-7 p-[0.938rem] min-[1399px]:p-[0.875rem] rounded-md focus:outline-none foucs:border-b-[#C8CBD2] max-[1440px]:leading-[1rem] max-[1440px]:text-[0.95rem]"
              textAreaCss="placeholder-[#000] max-[1440px]:h-24 max-[991px]:h-40 text-lg max-[1440px]:leading-[0.75rem] max-[1366px]:py-[0.625rem] max-[1366px]:px-7 p-[0.938rem] min-[1399px]:p-[0.875rem]  bg-white rounded-md border focus:outline-none focus-visible:outline-none max-[1440px]:text-[0.95rem]"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default PgHdr
