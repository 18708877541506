import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Contract from "../../../assets/img/contract.inline.svg"
import Countly from "../../../assets/img/countly-logo.inline.svg"
import GetLitt from "../../../assets/img/getlit-logo.inline.svg"
import Gravity from "../../../assets/img/gravity.inline.svg"
import HP from "../../../assets/img/hp.inline.svg"
import Shell from "../../../assets/img/shell.inline.svg"
import Time from "../../../assets/img/time.inline.svg"
import Tutorac from "../../../assets/img/tutorac-logo.inline.svg"

const ReactDevWebsiteArticles = ({ setShowModal }) => {
  return (
    <section className="lg:pb-[5.625rem] md:pb-10">
      <div className="container py-[1.875rem] md:py-[5.625rem]">
        <div className="grid gap-2 lg:gap-[6.875rem] lg:grid-cols-[380px_1fr]">
          <h2 className="md:text-[2rem] text-[1.625rem] md:leading-[3.25rem] leading-[2.4rem] font-blancotrialbold text-center lg:text-left">
            Trusted by 200+ startups and enterprises.
          </h2>
          <div className="items-center flex flex-wrap justify-center lg:justify-between">
            <GetLitt />
            <Countly className="ml-5" />
            <Tutorac className="ml-5" />
            <Shell className="ml-5" />
            <Gravity className="ml-5" />
            <HP className="ml-5" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="title-sec m-auto w-full max-w-full md:pb-10 pb-8">
          <h2 className="md:text-[2rem] text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] font-blancotrialbold text-center md:w-[28rem] mx-auto">
            Build your winning React team with ease and confidence
            <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
          </h2>
        </div>
        <ul className="flex justify-between flex-wrap mb-[1.875rem] md:mb-[3.75rem]">
          <li className="bg-white-300 md:mb-[1.875rem] mb-5 md:max-w-[calc(50%-0.938rem)] lg:p-10 py-10 px-7 flex flex-col text-center rounded-[1.25rem] ">
            <StaticImage
              src="../../../assets/img/badge.png"
              alt="bage"
              placeholder="blurred"
              className="w-[3rem] mx-auto"
            />
            <h3 className="md:text-2xl text-xl pb-1 font-blancotrialbold leading-9 text-center pt-5">
              Work with the top 3%
              <span className="bg-orangecol w-[6px] h-[6px] rounded-full inline-block ml-1"></span>
            </h3>
            <p className="text-grey text-base leading-7">
              Work with the best-in-class engineers that provide true value to
              your project and business. With our meticulous recruitment process
              designed to hire just the best talents, we handpick the top 3
              percent of applicants who stand upon our hiring standards of
              technical capabilities, communication, problem-solving abilities
              and behavioral skills.
            </p>
          </li>
          <li className="bg-white-300 md:mb-[1.875rem] mb-5 md:max-w-[calc(50%-0.938rem)] w-full max-w-full lg:p-10 py-10 px-7 flex flex-col text-center rounded-[1.25rem] ">
            <Time className="w-[3rem] mx-auto" />
            <h4 className="md:text-2xl text-xl pb-1 font-blancotrialbold leading-9 text-center pt-5">
              On board within 48 hours
              <span className="bg-orangecol w-[6px] h-[6px] rounded-full inline-block ml-1"></span>
            </h4>
            <p className="text-grey text-base leading-7">
              Get the developers working on your project in as little as 48
              hours. Just share your project requirements, select the developers
              who fit your technical and experience requirements, and get them
              on board. It’s that quick.
            </p>
          </li>
          <li className="bg-white-300 md:mb-[1.875rem] mb-5 md:max-w-[calc(50%-0.938rem)] w-full max-w-full lg:p-10 py-10 px-7 flex flex-col text-center rounded-[1.25rem] ">
            <StaticImage
              src="../../../assets/img/team.png"
              alt="bage"
              placeholder="blurred"
              className="w-[3rem] mx-auto"
            />
            <h4 className="md:text-2xl text-xl pb-1 font-blancotrialbold leading-9 text-center pt-5">
              Flexibility to scale up or down
              <span className="bg-orangecol w-[6px] h-[6px] rounded-full inline-block ml-1"></span>
            </h4>
            <p className="text-grey text-base leading-7">
              Stay agile and responsive to your project and business needs with
              easy scale-up or scale-down. Need more output during peak periods
              or want to reduce time to market? Add more developers. Did the
              workload decrease or want to cut the budget? This time, take off
              developers from your team.
            </p>
          </li>
          <li className="bg-white-300 md:mb-[1.875rem] mb-5 md:max-w-[calc(50%-0.938rem)] w-full max-w-full lg:p-10 py-10 px-7 flex flex-col text-center rounded-[1.25rem]">
            <Contract className="w-[3rem] mx-auto" />
            <h4 className="md:text-2xl text-xl pb-1 font-blancotrialbold leading-9 text-center pt-5">
              Simple and transparent contracts
              <span className="bg-orangecol w-[6px] h-[6px] rounded-full inline-block ml-1"></span>
            </h4>
            <p className="text-grey text-base leading-7">
              We offer simple contracts with monthly billing based on man-hours.
              T his means you only pay for the time spent on your project and
              also better control over your project budget. All the fully
              documented and transparent, giving you complete visibility into
              the work being done and the associated costs.
            </p>
          </li>
        </ul>
      </div>
      <section className="bg-black py-[1.875rem] lg:py-16">
        <div className="container">
          <div className="flex flex-wrap md:flex-nowrap md:justify-between justify-center items-center lg:px-20 px-0 text-center md:text-left">
            <div className="mb-[1.25rem] md:mb-0 w-full md:max-w-[28.625rem]">
              <h3 className="text-white text-[1.875rem] md:text-[2rem] font-blancotrialmedium leading-[2.875rem] md:leading-[3.25rem] mb-0">
                You’re just a step way from building your winning team.
              </h3>
            </div>
            <div className="w-full md:w-auto">
              <button
                type="button"
                onClick={() => setShowModal(true)}
                className="inline-white text-white bg-primary rounded-[1.875rem] px-[2rem] py-4 font-gorditamedium hover:bg-orange-500 hover:text-white transition-all duration-500 md:w-[15.625rem] lg:w-[22.5rem] text-xl"
              >
                Get started
              </button>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}
export default ReactDevWebsiteArticles
