import React, { useEffect, useState } from "react"
import PPCFooter from "../components/PPC/Footer"
import Modal from "../components/PPC/Modal"
import ModalForm from "../components/PPC/ModalForm"
import ClutchReviews from "../components/PPC/ReactDevPpc/ClutchReviews"
import Faq from "../components/PPC/ReactDevPpc/Faq"
import Header from "../components/PPC/ReactDevPpc/Header"
import HiringProc from "../components/PPC/ReactDevPpc/HiringProc"
import PerfomantDevs from "../components/PPC/ReactDevPpc/PerfomantDevs"
import PgHdr from "../components/PPC/ReactDevPpc/PgHdr"
import Portfolio from "../components/PPC/ReactDevPpc/Portfolio"
import Pricing from "../components/PPC/ReactDevPpc/Pricing"
import TalentedDevs from "../components/PPC/ReactDevPpc/TalentedDevs"
import Technologies from "../components/PPC/ReactDevPpc/Technologies"
import VideoSection from "../components/PPC/ReactDevPpc/VideoSection"
import ReactDevWebsiteArticles from "../components/PPC/ReactDevPpc/WebsiteArticles"
import Seo from "../components/SEO"
import { useContextValues } from "../context/NavBarContext"

const HireReactDevelopersIndia = () => {
  const { changeCurrentPath } = useContextValues()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => changeCurrentPath(location.pathname), [])
  return (
    <>
      <Header setShowModal={setShowModal} />
      <section className="bg-black md:pb-10 py-8 pt-[0.638rem]">
        <PgHdr currency="INR" />
      </section>
      <ReactDevWebsiteArticles setShowModal={setShowModal} />
      <TalentedDevs setShowModal={setShowModal} />
      <PerfomantDevs setShowModal={setShowModal} />
      <Technologies />
      <Pricing
        hourlyPrice="₹1,200"
        monthlyPrice="₹1,30,000"
        setShowModal={setShowModal}
      />
      <VideoSection setShowModal={setShowModal} />
      <ClutchReviews />
      <Portfolio />
      <HiringProc setShowModal={setShowModal} />
      <Faq setShowModal={setShowModal} />
      <Modal showModal={showModal}>
        <ModalForm
          currency="INR"
          toggleModal={setShowModal}
          formTitle="Share your requirements"
        />
      </Modal>
      <PPCFooter
        showCorporateLocs
        currency="INR"
        hrsOfWorkDelv="1,30,000+"
        reviews="32"
      />
    </>
  )
}
export const Head = () => <Seo title="Hire React Developers India" noIndex />

export default HireReactDevelopersIndia
