import React from "react"
import StreamComponent from "../../StreamComponent"
const VideoSection = ({ setShowModal }) => {
  return (
    <section className="lg:pt-14 md:pt-10 pt-8 lg:pb-[5.625rem] md:pb-[10] pb-8">
      <div className="title-sec m-auto w-full max-w-full md:pb-7 pb-5 px-[1rem]">
        <h2 className="md:text-[2rem] text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] pb-1 font-blancotrialbold text-center">
          We’re a fun, friendly, and professional team to work with
          <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
        </h2>
      </div>
      <div className="container max-w-[46.5rem] relative z-10 p-0">
        <StreamComponent />
      </div>
      <div className="flex justify-center flex-wrap lg:mt-[5.813rem] mt-10 md:px-0 px-[1rem]">
        <button
          onClick={() => {
            setShowModal(true)
          }}
          className="inline-white text-white bg-primary text-base rounded-3xl px-[2rem] py-[0.7rem] font-gorditamedium hover:bg-orange-500 hover:text-white transition-all duration-500"
        >
          Hire top engineers
        </button>
      </div>
    </section>
  )
}
export default VideoSection
