import React from "react"
import ClutchWidget from "../../ClutchWidget"

const ClutchReviews = () => {
  return (
    <div className="w-full pb-[1.875rem] lg:pb-[5.625rem] title-sec">
      <p className="text-base md:pb-7 pb-0 md:font-gorditamedium font-gorditaregular leading-[1.7rem] text-center text-orangecol mb-5 md:mb-0">
        According to Clutch.co
      </p>
      <h2 className="text-[1.625rem] px-4 md:py-0 md:text-3xl md:pb-1 pb-6 font-blancotrialbold md:leading-[3rem] leading-[2.5rem] text-center lg:max-w-[36.125rem] mx-auto">
        Techuz is one of the best development and IT firm in the world.
        <span className="inline-block w-2 h-2 ml-1 rounded-full bg-orangecol" />
      </h2>
      <p className="text-base md:pb-7 pb-0 md:font-gorditamedium font-gorditaregular leading-[1.7rem] text-center text-grey">
        And here’s what our clients say about us
      </p>
      <ClutchWidget />
    </div>
  )
}

export default ClutchReviews
