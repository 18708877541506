import React from "react"

const ClutchWidget = () => (
  <div
    className="clutch-widget lg:max-w-[50%] mx-auto"
    data-url="https://widget.clutch.co"
    data-widget-type="8"
    data-height="300"
    data-nofollow="true"
    data-expandifr="true"
    data-scale="100"
    data-reviews="2091465,2082629,2081480,2074399,1979679,1777005"
    data-clutchcompany-id="65154"
  ></div>
)

export default ClutchWidget
